import { render, staticRenderFns } from "./WashHome.vue?vue&type=template&id=79a792c1&scoped=true&"
import script from "./WashHome.vue?vue&type=script&lang=js&"
export * from "./WashHome.vue?vue&type=script&lang=js&"
import style0 from "./WashHome.vue?vue&type=style&index=0&id=79a792c1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79a792c1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCard,VCardTitle,VCarousel,VCarouselItem,VContainer,VFlex,VIcon,VLayout})
